import {atom, SetterOrUpdater} from 'recoil';
import {modalTypes} from "../types/modal";

export const modalState = atom<modalTypes>({
    key: 'modal',
    default: {
        show: false,
        children: '',
    }
});

export const showModal = (hook: SetterOrUpdater<any>, {children}: Pick<modalTypes, 'children'>) => {
    hook({
        show: true,
        children,
    });
};

export const closeModal = (hook: SetterOrUpdater<any>) => {
    hook({
        show: false,
        children: ''
    });
};
